.faq-list-area {
    margin: 0 auto;
    margin-top: 130px;
    margin-bottom: 100px;

    .faq-heading-container {
        margin: 150px auto 50px;
    }

    .question {
        font-weight: 500;
        // min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a {
        text-decoration: underline;
    }

    .accordion {
        margin-top: 40px;
        button {
            font-size: 18px;
            font-weight: 500;
            background-color: $theme-color;
            border: none;
            color: #494949;
            padding: 13px;
            margin: 0;
            width: 100%;
            // text-align: center;
            text-align: left;
            border: 1px solid #fff;
        }

        .answer{
            padding: 15px;
            margin-bottom: 20px;
            border: 1px solid #ebebeb;
        }
    }

}
