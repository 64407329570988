/*----- 20. Cart page  -----*/

.cart-main-area {
  margin-top: 40px;
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0 20px;
  // border-bottom: 1px solid #ebebeb;
  // padding-bottom: 15px;
  // margin-bottom: 27px;
  @media (max-width: 576px) {
    margin-bottom: -1px;
  }
  .cart-item-number {
    color: #7f7f7f;
  }
}

@media (max-height: 400px) {
  .cart-table {
    margin-bottom: 100px;
  }
}

.cart-table-content {
  @media (max-width: 768px) {
    display: none;
  }
  table {
    // border-top: 1px solid #ebebeb;
    // border-bottom: 1px solid #ebebeb;

      border-collapse: collapse;
      border-radius:7px;
      overflow: hidden;

    width: 100%;
    thead > tr {
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px #ddd;
      border-radius:5px;
      th {
        // border-top: medium none;
        color: #333;
        font-size: 14px;
        font-weight: 500;
        // padding: 21px 63px 22px 42px;
        padding: 14px 63px 13px 42px;
        vertical-align: middle;
        white-space: nowrap;
        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }
        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }
    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td.product-thumbnail-name-container {
        width: 56%;
      }
      .name-quantity {
        display: flex;
        align-items: center;
      }

      .product-thumbnail-name {
        display: flex;
        margin-left: 15px;
        margin-bottom: 15px;
        .product-thumbnail {
          height: 66px;
          border-radius: 6px;
          border: 1px solid #ebebeb;
          min-width: 100px;
          cursor: pointer;
          max-width: 95px;
        }

        .product-name {
          font-size: 14px;
          cursor: pointer;
          line-height: 18px;
          max-width: 445px;
        }
        .price-remove-container {
          display: flex;
          flex-direction: column;
          align-items: baseline;
         
        }
      }
      .save_ltr{
        color:#047FB8;
        margin-left:15px;
        font-size:12px;
        cursor: pointer;
        @media (max-width: 576px) {
          display: table-row-group;
          color: #047FB8;
          margin-left: 15px;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .remove-btn {
        border: none;
        background-color: transparent;
        font-size: 12px;
        // color: $theme-color;
        color:#BE1033;
        margin-left: 15px;
      }
      td.product-name {
        width: 435px;
        text-align: left;

        a {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-price-cart {
        width: 435px;
        span {
          font-weight: 500;
          color: #333;
          &.old {
            text-decoration: line-through;
            margin-right: 10px;
            color: #8e8e8e;
          }
        }
      }
      td.product-subtotal {
        // font-weight:600;
        color: #333;
      }
      td.product-quantity {
        width: 435px;
        .cart-plus-minus {
          display: inline-block;
          height: 40px;
          padding: 0;
          position: relative;
          width: 110px;
          .qtybutton {
            color: #333;
            cursor: pointer;
            float: inherit;
            background: none;
            border: none;
            font-size: 16px;
            margin: 0;
            position: absolute;
            transition: all 0.3s ease 0s;
            width: 20px;
            text-align: center;
          }
          .dec.qtybutton {
            border-right: 1px solid #e5e5e5;
            height: 40px;
            left: 0;
            top: 0;
          }
          .inc.qtybutton {
            border-left: 1px solid #e5e5e5;
            height: 40px;
            right: 0;
            top: 0;
          }
          // input.cart-plus-minus-box {
          //   color: #333;
          //   float: left;
          //   font-size: 14px;
          //   height: 40px;
          //   margin: 0;
          //   width: 110px;
          //   background: transparent none repeat scroll 0 0;
          //   border: 1px solid #e1e1e1;
          //   padding: 0;
          //   text-align: center;
          // }
        }
        .medical-quantity{
          text-align: center;
          width: 75px;
          margin-left: 15px;
        }
      }
      td.product-remove {
        width: 100px;
        a,
        button {
          color: #666;
          background: none;
          border: none;
          font-size: 17px;
          margin: 0 13px;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-wishlist-cart > a,
      td.product-wishlist-cart > button {
        background: none;
        border: none;
        background-color: $theme-color;
        border-radius: 50px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        padding: 10px 15px;
        text-transform: uppercase;
        display: block;
        margin: 0 auto;
        &:hover,
        &.active {
          background-color: #333;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      td {
        color: #333;
        font-size: 15px;
        padding: 30px 0 30px 45px;
      }
    }
  }
  .order-info {
    margin: 20px 0;
    color: #9c9c9c;
  }
}

.cards-container {
  @media (min-width: 768px) {
    display: none;
  }
  .save_ltr{
    color:#047FB8;
    margin-left:15px;
    font-size:12px;
    cursor: pointer;
    @media (max-width: 576px) {
      display: table-row-group;
      color: #047FB8;
      margin-left: 15px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .course-card {
    border-top: 1px solid #ebebeb;
    padding: 15px 0;
    display: flex;
    align-items: end;
    .card-thumbnail {
      height: 90px;
    }
    .card-content {
      margin-left: 20px;
      .product-name {
        font-weight: 500;
        line-height: 16px;
      }
      .product-price {
        font-weight: 600;
      }
      .quantity-changer {
        transform: scale(0.8);
        margin-left: -8px;
        margin-top: 5px;
      }
      .remove-btn {
        border: none;
        background-color: transparent;
        font-size: 12px;
        display: block;
        // color: $theme-color;
        color:#d32f2f;
        margin-left: -7px;
        margin-top: -5px;
      }
    }
  }
  .course-card:first-child {
    border-top: none;
  }
}

.order-info-card {
  border-top: 1px solid #ebebeb;
  text-align: center;
  padding-top: 10px;
  color: #aaaaaa;
}

.save-later {
  text-align: center;
  font-size: 20px;
}

.order-details-container {
  // border-left: 1px solid #ebebeb;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 1px #ddd;
  margin-bottom: 10px;
  margin-left: 15px;
  @media (max-width: 768px) {
    border-left: none;
    margin-top: 40px;
  }
  // height: 100%;
  .order-details-title-container {
    border-bottom: 1px solid #ebebeb;
    @media (max-width: 768px) {
      border-top: 1px solid #ebebeb;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      padding: 20px;
      margin: 0;
    }
  }
  .subtotal-tax-container {
    border-bottom: 1px solid #ebebeb;
    padding: 20px;
    .subtotal,
    .tax {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 16px;
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }
  .grand-total-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #ebebeb;
    h4,
    p {
      font-weight: 600;
    }
  }
  .terms-check-container {
    display: flex;
    padding: 20px;
    margin-left: 20px;
    .form-check-input {
      position: static;
      min-height: 15px;
      min-width: 15px;
      height: 15px;
      width: 15px;
    }
    .form-check-label {
      margin-left: 15px;
      a {
        text-decoration: underline;
      }
    }
  }
  .check-out-btn-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    .checkout-btn-container {
      padding: 0 20px;
      text-align: center;
      margin-bottom: 10px;
      @media (max-width: 1150px) {
        padding: 0px 6px;
      }
      .checkout-btn {
        width:100%;
        margin:20px 0;
        padding: 14px 7px;
        @media (max-width: 576px) {
          width:90%;
      }
      }
    }
  }
  
  .discount-msg{
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;

    margin-top: 19px;
    padding: 13px;
    font-weight: 600;
    font-size: 15px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    color: #13984b
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px;
  @media #{$xs-layout} {
    display: block;
    padding: 30px 0px 15px;
  }
  .cart-shiping-update > a,
  .cart-clear > button,
  .cart-clear > a {
    background-color: #f2f2f2;
    border-radius: 50px;
    color: #363f4d;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 18px 63px 17px;
    text-transform: uppercase;
    @media #{$md-layout} {
      padding: 18px 25px 17px;
      font-size: 13px;
    }
    @media #{$xs-layout} {
      padding: 18px 40px 17px;
      margin: 0 0 15px;
    }
    &:hover {
      background-color: $theme-color;
      color: #fff;
    }
  }
  .cart-clear > button {
    border: medium none;
    cursor: pointer;
    margin-right: 27px;
    transition: all 0.3s ease 0s;
    &:last-child {
      margin-right: 0;
    }
    @media #{$md-layout} {
      margin-right: 15px;
    }
  }
}

.cart-tax,
.discount-code-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }
  @media #{$xs-layout} {
    padding: 45px 18px 50px;
  }
  .title-wrap {
    position: relative;
    &::before {
      background-color: #e3e1e1;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 10px;
      transition: all 0.4s ease 0s;
      width: 100%;
      z-index: 1;
    }
    h4.cart-bottom-title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding-right: 18px;
      @media #{$lg-layout} {
        font-size: 16px;
      }
    }
    .section-bg-gray {
      background-color: #f8f9f9;
      position: relative;
      z-index: 99;
    }
  }
  .tax-wrapper {
    margin-top: 22px;
    p {
      margin: 0;
    }
    .tax-select-wrapper {
      margin: 5px 0 0;
      .tax-select {
        margin: 0 0 26px;
        label {
          color: #242424;
          font-size: 14px;
          margin: 0 0 5px;
        }
        select {
          -moz-appearance: none;
          -webkit-appearance: none;
          //background: #fff url("../../assets/img/icon-img/cart.png") no-repeat scroll right 18px center;
          border: 1px solid #ebebeb;
          box-shadow: none;
          color: #242424;
          font-size: 12px;
          height: 40px;
          padding: 0 50px 0 15px;
          width: 100%;
          cursor: pointer;
        }
        input {
          background: #fff none repeat scroll 0 0;
          border: 1px solid #ebebeb;
          height: 40px;
        }
      }
      button.cart-btn-2 {
        background-color: $theme-color;
        border: medium none;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 42px 12px;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        &:hover {
          background-color: #333;
        }
      }
    }
  }
  .discount-code {
    margin: 21px 0 0;
    p {
      margin: 0 0 15px;
    }
    form {
      input {
        background: #fff;
        border: 1px solid #ebebeb;
        height: 40px;
        margin-bottom: 30px;
        padding-left: 10px;
      }
      button.cart-btn-2 {
        background-color: $theme-color;
        border: medium none;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 42px 12px;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        &:hover {
          background-color: #333;
        }
      }
    }
  }
}

.discount-code-wrapper {
  @media #{$xs-layout} {
    margin: 30px 0;
  }
}

.monthly-plan, .annual-plan {
  display: block;
  padding: 5px 0;
}

.cart-item-variation {
  margin-top: 10px;
  span {
    display: block;
  }
}

.drp{
  width:75%;
  font-weight:600;
  cursor: pointer;
}



@media screen and (max-width: '475px') {
  .save_ltr{
    display: block;
    color:#047FB8;
    font-size:12px;
    cursor: pointer;
  }

  .paid-duration-text {
    display: block;
    font-size: 12px;
  }
}

.cus-loader{
    position: absolute;
    top:140px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color:#6666662b;
    z-index: 100;
    .loader-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
}