.picture-headline-text-section {
    @media (max-width: 768px) {
        display: none;
    }
    padding:50px 0;
    // background-color: #EDF5FD;
    // margin-top: 30px;
    // background-image:url('https://assets.contentenablers.com/storefront/imgs/get_your_certificate_schar.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 450px;
    background-color: #fff;
    @media (min-width: 1366px) {
    }
    @media (min-width: 1701px) {
        background-size: cover;
        height: max-content;
    }
    @media (min-width: 1921px) {
        height: 700px;
    }
    
    
    .row{
        height: 454px;
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        @media (max-width: 768px) {
            display: inline-block;
            height: auto;
        }
        .box-img-item{
            // background-image:url('https://assets.contentenablers.com/storefront/imgs/schar_cert.png');
            height:320px;
            width: 450px;
            background-repeat: no-repeat;
            margin-top: 30px;
            margin-left: 30px;
            margin: auto;

            
            @media (max-width: 768px) {
                height:300px;
                width:100%;
                background-position: center;
                margin-top: 20px;
            }
        }
    }
    .para-18 {
        margin-top: 22px;
    }

    .c-text-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 300px;
        @media (max-width: 1100px) {
            height: auto;
        }
        .picture-headline-text{
            margin-top: 23px;
            @media (max-width: 1100px) {
                margin-top: 6px;
            }
            p{
                color: #fff;
                font-size: 1.7rem;
                font-weight: 300;
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        
        }
    
        .picture-headline-text-headline{
            color: #fff;
        }
    }

    
}
.picture-headline-text-section-mobile {
    @media (min-width: 769px) {
        display: none;
    }
    background-color: #776A66;
    background-image: linear-gradient(#7F716E, #605755, #37322E);
    background-repeat: no-repeat;
    background-size: contain;
    height: 300px;
    padding: 30px;
    
    .row{
        height: 454px;
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        @media (max-width: 768px) {
            display: inline-block;
            height: auto;
        }
        .box-img-item{
            // background-image:url('https://assets.contentenablers.com/storefront/imgs/schar_cert.png');
            height:320px;
            width: 450px;
            background-repeat: no-repeat;
            margin-top: 30px;
            margin-left: 30px;
            margin: auto;

            
            @media (max-width: 768px) {
                height:300px;
                width:100%;
                background-position: center;
                margin-top: 20px;
            }
        }
    }
    .para-18 {
        margin-top: 22px;
    }

    .c-text-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 300px;
        @media (max-width: 1100px) {
            height: auto;
        }
        .picture-headline-text{
            margin-top: 23px;
            @media (max-width: 1100px) {
                margin-top: 6px;
            }
            p{
                color: #fff;
                font-size: 1.7rem;
                font-weight: 300;
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        
        }
    
        .picture-headline-text-headline{
            color: #fff;
        }
    }

    
}

  
