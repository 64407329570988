.practitioner-course-detail-main-area {
    // margin-top: 100px;
    .hero-section {
      align-items: stretch;
      display: flex;
      // align-items: center;
      // justify-content: center;
      min-height: 500px;
    //   height: 450px;
      color: #fff;
      // background-color: #0f1868;
  
      background: rgb(7, 10, 52);
      background: linear-gradient(
        135deg,
        rgba(7, 10, 52, 1) 0%,
        rgba(82, 132, 184, 1) 100%
      );
  .overlay{
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: rgba(14, 20, 103, 0.3);
    z-index: 2;
    left: 0;
  }
  .hero-text-holder{
    z-index: 4;
    position: relative;
    margin-top: 50px;
    .content {
      // text-align: center;
      .heading {
        font-size: 44px;
        font-weight: 600;
        color: #fff;
        @media (max-width: 768px) {
          padding-top: 20px;
      }
        @media (max-width: 576px) {
          font-size: 20px;
          line-height: 22px;
        }
        @media (max-width: 370px) {
            margin-top: 6.5rem !important;
        }
      }
      .sub-heading {
        font-size: 18px;
        // @media (max-width: 576px) {
        //     font-size: 18px;
        // }
        padding: 20px 0 5px;
        font-weight: 400;
        color: #fff;
      }

      .mt5 {
        margin-top: 15px;
      }

      .btn-holder {
        display: flex;
        // justify-content: center;
        align-items: baseline;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px;

        .subscribe-btn {
          padding: 10px 35px;
          font-size: 15px;
        }

        .remove-cart-btn {
          color: #fff;
        }

        .prac-bundle-cost {
          color: #fff;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .cart-quantity-container {
          // margin: 0 15px;
          .add-to-cart-btn,
          .remove-cart-btn {
            padding: 10px 35px;
            font-size: 15px;
            // background-color: #fff;
          }
          .remove-cart-btn {
            // background-color: #fff;
            background-color: transparent;
            border-radius: 6px;
            color: #f3c565;
            background: #000;
          }
        }

        .recharge-price {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          margin-left: 23px;
          color: #fff;
          .price-monthly,
          .price-yearly {
            color: #fff;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
            max-width: 40%;

            @media (max-width: 992px) {
              font-size: 13px;
            }
          }
        }
      }

      .loading-text-price {
        color: #fff;
        font-size: 16px;
        margin-top: 20px;
      }
      @media (max-width: 576px) {
        .btn-holder {
          flex-direction: column;
          .cart-quantity-container {
            margin-top: 15px;
          }
        }
      }
    }
  }
      
    }
    .player-wrapper {
      iframe {
        min-height: 268px;
      }
    }
    .tile-background {
      background-image: url("../../assets/imgs/hero-background-img.png");
      background-size: cover;
      background-position: center;
    }
  
    .tile-2-background {
      background-image: url("../../assets/imgs/medical-hero-img.jpg");
      background-size: cover;
      background-position: center;
    }
    .tile-3-background {
      background-image: url("../../assets/imgs/Aerospace-Industry.png");
      background-size: cover;
      background-position: center;
    }
    .sec-courses-info {
      min-height: 400px;
      margin: 50px 0;
      .course-duration{
        font-weight:lighter;
        }
  
      .bundle_area {
        background: #fff;
        height: 525px;
        // width: 392px;
        margin: auto;
        border-radius: 5px;
        margin-top: 20px;
        position: relative;
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
        .t_info {
          background: linear-gradient(135deg, #0E1467 0%, #5284b8 100%);
          min-height: 150px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          .ins {
            padding: 23px;
            h3 {
              color: #fff;
              font-weight: 600;
              margin-bottom: 20px;
            }
            p {
              font-size: 16px;
              color: #fff;
            }
          }
        }
  
        ul {
          li {
            background-color: #ddd;
            color: #0E1467;
            font-weight: 500;
            padding: 15px;
            padding-left: 25px;
            font-size: 16px;
            border-bottom: 1px solid #fff;
            cursor: pointer;
          }
        }
        .b_info {
          .price {
            display: flex;
            margin-top:20px !important;
            height: 40px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            margin-top: 13px;
            span {
              color: #13984b;
            }
          }
          .b_btns {
            position: absolute;
            bottom: 0;
            width: 100%;
            border-radius: 5px !important;
            display:flex;
            .cart-quantity-container {
              width:50%;
              border-radius: 5px !important;
              .add-to-cart-btn {
                width: 100%;
                border-bottom-left-radius: 5px;
              }
            }
            .learn-more-button{
                width:50%;
                border-top:.5pxsolid#B8B4B4;
            }
            .learn-more-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width:50%;
                border: none;
                background-color: transparent;
                border-top:.5px solid #dddddd;
            }
          }
        }
      }
  
      .main-heading {
        color: #00a84f;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 12px;
      }
  
      .summary-para {
        margin-top: 20px;
      }
  
      .sub-heading {
        font-size: 24px;
        padding: 25px 0 20px;
        font-weight: 600;
        color: #00a84f;
      }
  
      .sub-sub-heading {
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;
      }
  
      .sub-hero-container {
        background-color: #f3f3f3;
        padding: 50px 0px;
        text-align: center;
      }
  
      .sub-text-container {
        margin: 15px 0px;
        ul {
          list-style-type: circle;
          margin-left: 30px;
          font-weight: 400;
          margin-bottom: 15px;
          li{
            color: #494949;
            span{
              font-size: 12px;
            }
          }
        }
      }
      .video-holder {
        margin: 20px 0px;
      }
      .course-brief {
        // margin-top: 50px;
        .tab-items {
          .brief-intro-video {
            display: flex;
            .video-section-course {
              min-width: 375px;
              margin: 30px 0 0 20px;
            }
          }
        }
        .acc-items {
          margin-top: 20px;
          margin-bottom: 20px;
  
          .accordion {
            margin-top: 10px;
            .card-header {
              padding: 0;
              font-size: 18px;
              .fa-plus,
              .fa-minus {
                position: absolute;
                right: 15px;
                top: 19px;
              }
              button {
                background-color: #13984b;
                border: none;
                color: #fff;
                padding: 13px;
                margin: 0;
                width: 100%;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .sec-content {
      // background-color: #f3f3f3;
      background: linear-gradient(135deg, #070a34 0%, #5284b8 100%);
      padding: 50px 0px;
      p {
        font-size: 16px;
        text-align: center;
        color: #fff;
      }
    }
  
    .prac-intro-row {
      margin-bottom: 30px;
      .video-section {
        max-width: 500px;
        margin: 0 auto;
      }
    }
    .pract-bundle-section {
      padding: 50px 0;
      background-color: #131313;
      .pract-bundle-inner-container {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        img {
          margin-right: 40px;
        }
        .content-container {
          @media (max-width: 768px) {
            text-align: center;
          }
          .main-heading {
            font-size: 24px;
            font-weight: 600;
            color: #fff;
          }
          .sub-heading {
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            margin-top: 5px;
          }
  
          .price {
            margin-top: 20px;
            color: #fff;
            font-size: 22px;
            span {
              color: #aecb48;
              font-weight: 600;
            }
          }
          .btn-container {
            display: flex;
            align-items: center;
            margin-top: 20px;
            @media (max-width: 768px) {
              justify-content: center;
            }
            .learn-more-btn {
              color: #fff;
              margin-left: 15px;
            }
            .remove-cart-btn {
              background-color: #eee;
            }
          }
        }
      }
    }
  
    .client-section {
      padding: 50px 0;
      // margin-bottom: -80px;
    }
  
    .loading-text {
      margin-top: 20%;
    }
  
    .featured-courses-section {
      padding: 50px 0;
      .col-md-12 {
        overflow: hidden;
      }
      .featured-heading {
        margin-bottom: 20px;
        font-size: 30px;
        width: 70%;
        font-weight: bold;
        margin: auto;
      }
      .explore-course-btn-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .explore-course-btn {
          text-transform: uppercase;
        }
      }
    }
  }
  .w_bg {
    background-color: #fff;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
  }
  .cus_width {
    margin-top: 20px;
    .price-monthly,
    .price-yearly {
      width: 70%;
      @media (max-width: '991px') {
          width: 100%;
      }
    }
  }
  
  .price-monthly,
  .price-yearly {
    display: flex;
    align-items: center;
    margin-top: 0px;
    color: #fff;
  
    span {
      font-size: 13px;
    }
    .info {
      color: #fff;
      margin-left: 10px;
      border-left: 1px solid #f3c565;
      padding-left: 10px;
      font-size: 12px;
      span {
        color: #f3c565;
      }
    }
  }
  
  .aero-section {
    padding: 20px 0px;
    .bg_w {
      background-color: #fff;
      margin-bottom: 0;
      margin: 7px 3px;
      min-height: 125px;
      .cer {
        display: flex;
        align-items: center;
        img {
          width: 160px;
          margin: 10px;
        }
      }
      h6 {
        margin: 10px 0px;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .row {
      margin-bottom: 20px;
      .bold-text {
        font-weight: 600;
      }
      p {
        font-size: 18px;
        line-height: 26px;
      }
      .flex-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        .fa-file-o {
          font-size: 20px;
        }
        a {
          color: #fff;
          text-decoration: none;
        }
        border: none;
        background-color: #000;
        color: #fff;
        padding: 10px 15px;
        border-radius: 6px;
        font-size: 18px;
      }
    }
    h5 {
      font-size: 25px;
      line-height: 24px;
      font-weight: 700;
    }
  }
  
  .mt10 {
    margin-top: 10px;
  }
  
  .para-18 {
    padding: 0px 16px;
  }
  
  .desc {
    padding: 0px 14px;
  }
  
  .desc15 {
    padding: 0px 14px;
  }
  .font-w600 {
    font-weight: 600;
  }
  
  .contact-link {
    color: #008000;
    text-decoration: none;
    font-weight: 600;
  }
  
  .font-w400 {
    font-weight: 400;
  }
  