.tabs_holder{
    margin-right: 8px;
    .MuiTabs-root .MuiTab-root{
        border-right: 1px solid #ffffff59;
    }
    .MuiTabs-root {
        background-color:#00A84F;
        border-radius:5px;
        padding:0px 10px;
    }

    .MuiTab-wrapper{
        color:#fff;
        text-align: left;
        word-break: break-word;
        text-transform: initial;
        display: block;
    }

    .Mui-selected {
        .MuiTab-wrapper{
            color:#F3C465
        }
    }
    
    .MuiTabs-indicator {
        background-color:#F3C465;
        padding:2px
    }

    .crs_des{
        padding:26px 23px;
        background-color: #fff;
        padding-left: 44px!important;
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
        border: 1px solid #e0e0e01c;
        border-radius: 6px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin-top: -1px;

        .row{
            .play_btn_h{
                display: flex;
                // justify-content: space-between;
                align-items: center;
                h5{
                    color:#13984b;
                    padding-left: 5px;
                }
                h6{
                  color:#00A84F;
                  font-weight: 600;
                  padding-left: 5px;  
                }
                .play_btn{
                    width: 50px;
                    height: 50px;
                    background-color: #ffe0a0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    box-shadow:0 2px 16px 4px rgba(40, 44, 63, 0.09);
                    cursor: pointer;
                    .fa-play{
                        font-size: 14px;
                        color:#13984b;
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    .MuiButtonBase-root .MuiSvgIcon-root {
        left: -50px;
        border-radius: 20px;
        width: 30px;
        height: 30px;
    }
    .MuiSvgIcon-fontSizeSmall{
        background: #F3C465;
    }
}
    
