// ====================== course-outline ===========================

.tree-container {
  color: #333;
  .tree-node > a {
    font-weight: 400;
    font-size: 15px;
  }
}

.tree-node-leaf a {
  font-weight: 400;
  font-size:13px;
}

.tree-node-children .tree-node-leaf a {
  font-weight: 400;
  // font-size:12px;
  font-family:'Work Sans', sans-serif;;
}

/* the tree node's style */
.tree-node {
  overflow-y: hidden;
}

/* style for the container */
.tree-node-children {
  margin-left: 30px;
}


.tree-node-arrow {
  cursor: pointer;
  margin-right: 6px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}




/* rotate the triangle to close it */
.tree-node-arrow-collapsed {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* style for the container */
.tree-node-children {
  margin-left: 30px;
}

.tree-node-children-collapsed {
  height: 0px;
}