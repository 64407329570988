.supplier-courses-main-area {
    .course-container {
        position: relative;
        top: 20px;
        max-width: 1200px;
        margin: 0 auto;
        min-height: 600px;
        .collection-row{
            @media (max-width: 768px) {
                justify-content: center;
            }
        }
        .collection-heading {
            font-weight: 500;
            font-size: 20px;
            @media (max-width: 992px) {
                font-size: 15px;
                line-height: 20px;
            }
            @media (max-width: 576px) {
                margin-left: -11px;
            }
        }

        .loading-text{
            margin-top: 25%;
        }
    }
}
