.user-course-list-container{
    background-color: #fff;
    box-shadow:0 2px 16px 4px rgba(40, 44, 63, 0.09);
    width: 100%;
    margin:10px 20px;
    margin-top:40px;
    border-collapse: collapse;
    border-radius:7px;
    overflow: hidden;
    .tp_header{
        width: 100%;
        height:51px;
        background-color:#E6E6E6;
    }
    tr{
        height:50px;
        border-bottom: 1px solid #ddd;
        box-shadow:0 2px 16px 4px rgba(40, 44, 63, 0.09);
    }
    th, td {
        padding: 15px;
    }
    .s_icon{
        margin-right:5px;
    }
    
  
}