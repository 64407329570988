.search-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .search-inner-container {
        width: 100%;
        .search-input {
            // border-radius: 50px;
            border-radius:6px;
            @media (max-width: 992px) {
                border: none;
            }
        }

        .search-input::-ms-clear {
            display: none;
        }
        .search-icon {
            position: relative;
            left: -25px;
            width: 0;
            cursor: pointer;
        }
    }

    .search-results-list {
        background-color: #fff;
        position: absolute;
        top: 40px;
        left: 0;
        box-shadow: 2px 3px 5px -1px rgba(0, 0, 0, 0.2);
        overflow-x: hidden;
        width: 100%;
        border-radius: 4px;
        @media(max-width: 576px){
            max-height: 40vh;
        }
        li {
            background-color: #fff;
            padding: 10px;
            border-bottom: 1px solid #e6e6e6;
            font-weight: 200;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            color: #333;
        }
        li:last-child {
            border: none;
        }
        .active {
            background-color: #ebebeb;
        }
        a:hover {
            color: $black;
        }
    }

    .no_info {
        position: absolute;
        top: 60px;
        width: 25%;
        margin-left: 20px;
        background-color: #fff;
        padding: 10px 5px;
        border: 1px solid #e6e6e6;
        font-weight: 200;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
