.slick-items {
    margin: 50px 0px;

    .slick-item {
        @media (min-width: 576px) {
            width: 150px;
        }
    }
    .client-logo {
        height: 85px;
        margin: 0 auto;
        @media (max-width: 576px) {
            height: 55px;
        }
    }
}
