.search-main-area {
    padding-bottom: 20px;
    @media (max-width: 1200px) {
        margin-top: 230px;
    }
    .search-heading {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 200;
        span {
            font-weight: 600;
        }
    }
    .no-course-found {
        text-align: center;
        width: 100%;
        margin-top: 10%;
        font-size: 20px;
        font-weight: 500;
    }
}
