.my-account-main-area {
  // max-width: 1200px;
  margin: 0 auto;
  margin-top:50px;
  // margin-bottom: 30px;
  .my-account-container {
    // border: 1px solid #ebebeb;
    border-radius: 6px;
    min-height:759px;
    padding-left:36px;
    

    .profile_info{
      background-color: #fff;
      box-shadow:0 2px 16px 4px rgba(40, 44, 63, 0.09);
      width:100%;
      border-radius: 5px;
      .l_panel{
        padding-bottom:15px;
      }
     
      .pf{
        background-color:#F3C465;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height:240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .pic{
          width:100px;
          height: 100px;
          border-radius: 50px;
          border: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ddd;
          .user-profile-image{
            width:70%;
          }

        }

      }
      .info{
          display: flex;
          flex-direction: column;
          align-items: center;
          // position: absolute;
          padding-top: 20px;
          bottom:10px;
          h5{
            font-size: 16px;
            font-weight: 600;
          }
          h6{
            font-size: 15px;
            padding-top: 10px;
            font-weight:500;
          }
      }

      ul{
        li{
          padding-left: 20px;
          padding-top: 20px;
          p{
            &:first-child{
              font-weight:400;
              font-size: 12px;
            }
          }
        }
      }
      .social_btns{
        padding-left: 20px;
        margin-top:30px;
        margin-bottom: 20px;
        .fb{
          margin-left:20px;
        }
      }
      .edit_holder{
        text-align: center;
        button{
          border: none;
          padding: 10px;
          min-width:150px;
          border-radius: 5px;
          background-color: #13984b;
          color: #fff;
          margin-top:20px;
        }
      }
    }
    @media #{$xs-layout} {
      // display: none;
    }
   
    .tab-bar-container {
      background-color: #f6f6f6 !important;
      width: 265px;
      min-height: 500px;
      position: relative;
      @media #{$xs-layout} {
        width: 100%;
      }
      .logout-container{
        @media #{$xs-layout} {
          display: block;
        }
        @media #{$lg-layout} {
          display: block;
        }
        display: inline-flex;
        position: absolute;
        bottom: 20px;
        border-top: 1px solid #ebebeb ;
        padding-top: 15px;
        padding-left: 15px;
        width: 100%;
        .logout-name{
          padding-left: 10px;
          color: #707070;
          font-weight: 500;
        }
        .tab-icon {
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          height: 20px;
          width: 20px;
        }
      }
      .user-profile-info-container {
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid #ebebeb;
        .user-profile-image {
          border-radius: 50%;
        }
        .user-initials {
          background-color: #fff;
          
          margin: 0 auto;
          
          border-radius: 50%;
          font-size: 20px;
          text-transform: uppercase;
          @media #{$xl-layout} {
            width: 70px;
            height: 70px;
            line-height: 70px;
          }
          @media #{$lg-layout} {
            width: 70px;
            height: 70px;
            line-height: 70px;
          }
         
          @media #{$xs-layout} {
            width: 90px!important;
            height: 90px!important;
            line-height: 90px!important;
          }
        }
        .user-name {
          font-size: 16px;
          font-weight: 500;
          margin-top: 5px;
        }
        .user-email {
          font-size: 14px;
          font-weight: 400;
          color: #494949;
          margin-top: -6px;
          font-weight: 300;
        }
      }

      .tab-container {
        display: flex;
        align-items: center;
        position: relative;
        .tab-icon {
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          height: 20px;
          width: 20px;
        }
        .tab-name {
          margin-left: 15px;
        }
        .fa-chevron-right {
          position: absolute;
          display: none;
          right: 15px;
          color: #707070;
          font-size: 11px;
        }
      }
      .active {
        .fa-chevron-right {
          @media #{$xs-layout} {
            display: none !important;
          }
            display: block !important;
        }
      }
      .nav-item {
        border-bottom: 1px solid #ebebeb;
        @media #{$xs-layout} {
          background-color: #fff !important;
        }
        .nav-link:hover {
          color: $black;
        }
        .nav-link.active {
          background-color: #fff !important;
          color: $black;
          border-radius: 0;
        }
      }
    }

    .tab-content-container {
      width: calc(100% - 265px);

      .tab-content {
        .tab-header {
          padding: 10px 15px;
          border-bottom: 1px solid #ebebeb;
          .tab-heading {
            font-size: 20px;
            font-weight: 600;
            color: #494949;
          }
        }
        .tab-body {
          padding: 15px;
          .tab-sub-heading {
            font-size: 18px;
            font-weight: 500;
            color: #494949;
          }
        }
      }

      .my-profile-tab-content {
        .label {
          font-size: 13px;
          margin: 0;
          font-weight: 200;
          margin-top: 15px;
        }
        .text-box {
          background-color: #fff;
          border: 1px solid #e6e6e6;
          border-radius: 3px;
        }
        .minimum-msg {
          font-size: 12px;
          color: #929292;
          float: right;
          margin-top: -6px;
        }
      }

      // .my-courses-content {
      //   .card-row {
      //     // justify-content: space-around;
      //     padding: 0 30px;
      //     .course-card-container {
      //       border: 1px solid #ebebeb;
      //       border-radius: 6px;
      //       margin: 15px 5px 0;
      //       .card-inner-container {
      //         width: 250px;
      //         padding: 10px;

      //         .thumbnail {
      //           width: 100%;
      //         }
      //         .crs-name {
      //           font-size: 12px;
      //           min-height: 60px;
      //           line-height: 17px;
      //         }
      //         .progress {
      //           height: 10px;
      //           border-radius: 10px;
      //         }
      //       }
      //       .resume-btn {
      //         border-top-left-radius: 0;
      //         border-top-right-radius: 0;
      //         margin-top: 5px;
      //       }
      //       .status-text {
      //         font-size: 12px;
      //       }
      //       .text-right {
      //         text-align: right;
      //       }
      //     }
      //   }
      // }

      .manage-subscriptions-content {
        .unsubscribe-btn {
          color: $theme-color;
          font-size: 12px;
        }
        .course-card-content{
          display: inline-flex;
        }
        .course-info{
          font-size: 12px;
          font-weight: 500;
        }
        .course-details{
          font-size: 10px;
          padding-left: 10px;
        }
      }

      .purchase-history-content {
        .fa-check-circle {
          color: #06964e;
        }
        .fa-times-circle {
          color: #d60a0a;
        }

        .status-success,
        .status-failed {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 56px;
        }
        .status-success {
          color: #06964e;
          .fa-times-circle {
            display: none;
          }
        }
        .status-failed {
          color: #d60a0a;
          .fa-check-circle {
            display: none;
          }
        }
        .purchase-card-content{
          display: inline-flex;
        }
        .purchase-info{
          font-size: 12px;
          width: 100px;
        }
        .purchase-details{
          font-size: 10px;
          //padding-left: 20px;
        }
        .purchase-course-name{
          font-size: 10px;
          height: 30px;
          line-height: 12px;
        }
      }

      // table common
      .cart-table-content {
        margin-top: 15px;
        table {
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          width: 100%;
          thead > tr {
            background-color: #f9f9f9;
            border: 1px solid #ebebeb;
            th {
              border-top: medium none;
              color: #333;
              font-size: 14px;
              font-weight: 500;
              padding: 15px 40px 15px;
              vertical-align: middle;
              white-space: nowrap;
              @media #{$lg-layout} {
                padding: 21px 35px 22px;
              }
              @media #{$xs-layout} {
                padding: 21px 20px 22px;
              }
            }
          }
          tbody > tr {
            border-bottom: 1px solid #ebebeb;
            td.product-thumbnail-name-container {
              width: 56%;
            }
            .name-quantity {
              display: flex;
              align-items: center;
            }
            .remove-btn {
              margin-left: 15px;
              border: none;
              background-color: transparent;
              font-size: 12px;
              color: #8f8f8f;
            }
            .product-thumbnail-name {
              display: flex;
              .product-thumbnail {
                width: 150px;
              }
              .product-name {
                text-align: left;
                margin-left: 20px;
                font-size: 18px;
              }
            }
            td.product-name {
              width: 435px;
              text-align: left;
              a {
                color: #333;
                font-size: 15px;
                font-weight: 500;
                &:hover {
                  color: $theme-color;
                }
              }
            }
            td.product-price-cart {
              width: 435px;
              span {
                font-weight: 500;
                color: #333;
                &.old {
                  text-decoration: line-through;
                  margin-right: 10px;
                  color: #8e8e8e;
                }
              }
            }
            td.product-subtotal {
              font-weight: 500;
              color: #333;
            }
            td.product-quantity {
              width: 435px;
              .cart-plus-minus {
                display: inline-block;
                height: 40px;
                padding: 0;
                position: relative;
                width: 110px;
                .qtybutton {
                  color: #333;
                  cursor: pointer;
                  float: inherit;
                  background: none;
                  border: none;
                  font-size: 16px;
                  margin: 0;
                  position: absolute;
                  transition: all 0.3s ease 0s;
                  width: 20px;
                  text-align: center;
                }
                .dec.qtybutton {
                  border-right: 1px solid #e5e5e5;
                  height: 40px;
                  left: 0;
                  top: 0;
                }
                .inc.qtybutton {
                  border-left: 1px solid #e5e5e5;
                  height: 40px;
                  right: 0;
                  top: 0;
                }
                // input.cart-plus-minus-box {
                //   color: #333;
                //   float: left;
                //   font-size: 14px;
                //   height: 40px;
                //   margin: 0;
                //   width: 110px;
                //   background: transparent none repeat scroll 0 0;
                //   border: 1px solid #e1e1e1;
                //   padding: 0;
                //   text-align: center;
                // }
              }
            }
            td.product-remove {
              width: 100px;
              a,
              button {
                color: #666;
                background: none;
                border: none;
                font-size: 17px;
                margin: 0 13px;
                &:hover {
                  color: $theme-color;
                }
              }
            }
            td.product-wishlist-cart > a,
            td.product-wishlist-cart > button {
              background: none;
              border: none;
              background-color: $theme-color;
              border-radius: 50px;
              color: #fff;
              font-size: 13px;
              font-weight: 500;
              line-height: 1.2;
              padding: 10px 15px;
              text-transform: uppercase;
              display: block;
              margin: 0 auto;
              &:hover,
              &.active {
                background-color: #333;
              }
              &:disabled {
                cursor: not-allowed;
              }
            }
            td {
              color: #333;
              font-size: 13px;
              padding: 8px 0 8px 41px;
              line-height: 20px;
            }
          }
        }
        .order-info {
          margin: 20px 0;
          color: #9c9c9c;
        }
      }

      .no-course-msg {
        text-align: center;
        margin-top: 80px;
        font-weight: 600;
      }
    }
  }

  .my-courses-content {
    .card-row {
      padding: 0 30px;
      .course-card-container {
        border: 1px solid #ebebeb;
        border-radius: 6px;
        margin: 15px 5px 0;
        .card-inner-container {
          @media #{($xl-layout)} {
            width: 250px;
          }
          @media #{($xx-layout)} {
            width: 250px;
          }
          @media #{($lg-layout)} {
            width: 250px;
          }
          @media #{($md-layout)} {
            width: 250px;
          }
          @media #{($xs-layout)} {
            width: 150px;
          }
          padding: 10px;
          @media only screen and (min-width: 1366px) {
            width: 250px;
          } 
          .thumbnail {
            width: 100%;
           
          }
          .crs-name {
            font-size: 12px;
            min-height: 60px;
            line-height: 17px;
          }
          .progress {
            height: 10px;
            border-radius: 10px;
          }
        }
        .resume-btn {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-top: 5px;
        }
        .status-text {
          font-size: 12px;
          @media #{($xs-layout)} {
            font-size: 8px;
          }
          
        }
        .text-right {
          text-align: right;
          margin-top: -25px;
        }
      }
    }
  }

  .user-profile-info-container {
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    background-color: #f6f6f6 !important;
    width: 100%;
    @media #{$xs-layout} {
      display: inline-flex;
    }
    .profile-container{
      display: inline-flex;
    }
    .user-profile-image {
      border-radius: 50%;
    }
    .user-initials {
      @media #{$xl-layout} {
        margin: 0 auto;
      }
      @media #{$lg-layout} {
        margin: 0 auto;
      }
      @media #{$md-layout} {
        margin: 0 auto;
      }
      @media #{$xs-layout} {
        margin-left: 10px;
        width: 90px;
        height: 90px;
        line-height: 90px;
      }
      background-color: #fff;
      width: 70px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      border-radius: 50%;
      font-size: 20px;
      text-transform: uppercase;
    }
    
    .logout-container{
      @media #{$xs-layout} {
        display: block;
        display: inline-flex;
      }
      @media #{$lg-layout} {
        display: block;
      }
      .tab-icon {
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 20px;
        width: 20px;
      }
    }
    .logout-name{
      margin-left: 10px;
      font-weight: 500;
      color:#707070;
    }
    .name-container{
      @media #{$xs-layout} {
        text-align : left;
        margin-left: 15px;
      }
    }
    .user-name {
      font-size: 16px;
      font-weight: 500;
      margin-top: 5px;
    }

    .user-email {
      @media #{$xl-layout} {
        font-size: 14px;
        font-weight: 400;
        color: #494949;
        margin-top: -6px;
        font-weight: 300;
      }
      @media #{$lg-layout} {
        font-size: 14px;
        font-weight: 400;
        color: #494949;
        margin-top: -6px;
        font-weight: 300;
      }
      @media #{$md-layout} {
        font-size: 14px;
        font-weight: 400;
        color: #494949;
        margin-top: 10px;
        font-weight: 300;
      }
      @media #{$xs-layout} {
        font-size: 14px;
        font-weight: 400;
        color: #494949;
        font-weight: 300;
      }
    }
  }
  .my-profile-tab-content {
    
    .label {
      font-size: 13px;
      margin: 0;
      font-weight: 200;
      margin-top: 15px;
    }
    .text-box {
      background-color: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
    }
    .minimum-msg {
      font-size: 12px;
      color: #929292;
      float: right;
      margin-top: -6px;
    }
  }
  .mobile-my-profile{
    //background-color: #f6f6f6 !important;    
    .active-panel{
      .fa-chevron-down{
        display: none;
      }.fa-chevron-up{
        display: block;
      }
    }
    @media only screen and (min-width: 778px) {
        display: none;
    }
   
    .tab-sub-heading {
      font-size: 18px;
      font-weight: 500;
      color: #494949;
      padding: 10px;
    }
    .subscription-details{
      padding: 10px;
    }
    .tab-content {
      .tab-header {
        padding: 10px 15px;
        border-bottom: 1px solid #ebebeb;
        .tab-heading {
          font-size: 20px;
          font-weight: 600;
          color: #494949;
        }
      }
      .tab-body {
        padding: 15px;
        .tab-sub-heading {
          font-size: 18px;
          font-weight: 500;
          color: #494949;
        }
      }
    }
    .btn-button{
      width: 100%;
      text-align: center;
      background-color: #fff!important;
      line-height: 40px;
      border-bottom: 1.5px solid lightgrey;
      border-radius: 0px;
    }
    .fa-chevron-down {
      position: absolute;
      right: 15px;
      color: #707070;
      font-size: 11px;
    }
   
    .fa-chevron-up{
      position: absolute;
      right: 15px;
      color: #707070;
      font-size: 11px;
    }
    .tab-icon {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 20px;
      width: 20px;
    }
    .active{
      .fa-chevron-down {
        @media #{$xs-layout} {
          display: block !important;
        }
      }
    }
  }
  .subcription-card{
    border: 1px solid lightgrey;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    .product-info{
      display: inline-flex;
    }
    .product-name{
      font-weight: 600;
      font-size: 14px;
      color:$black;
    }
    .purchase-date{
      font-weight: 600;
      font-size: 14px;
      color:$black;
    }
    .product-text-price{
      text-align: center;
      font-size: 14px;
    }
    .product-price{
      text-align: center;
      font-size: 14px;
      color:#494949;
      font-weight: 500;
    }
    .product-dtls{
      color:#494949;
      font-weight: 500;
    }
    .product-status{
      text-align: center;
      color:#494949;
    }
    .payment{
      font-weight: 600;
      color: $black;
    }
    .product-text{
      font-weight: 600;
      color: $black;
    }
    .product-left{
      color:#494949;
      font-weight: 500;
    }
    .product-right{
      color:#494949;
      text-align: center;
      font-weight: 500;
    }
    .payment-type-text{
      color:$black;
      font-weight: 600;
    }
    .product-pad{
      padding: 10px 0px;
    }
    .dotted-border{
      //border: 1px solid red;
      border-bottom-color: lightgrey;
      border-bottom-width: 2px;
      border-bottom-style: dashed;
    }
    .unsubscribe-btn {
      color: $theme-color;
      font-size: 14px;
      padding-top: 10px;
    }
    .pl-25{
      padding-left:25px
    }
    .status-success,
      .status-failed {
        //display: flex;
        align-items: center;
        //justify-content: space-between;
        height: 56px;
        
      }
      .status-success {
        color: #06964e;
        padding-left:25px;
        .fa-times-circle {
          display: none;
        }
      }
      .status-failed {
        padding-left:10px;
        color: #d60a0a;
        .fa-check-circle {
          display: none;
        }
      }
  }
  

  // ========================================

  .single-my-account {
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .panel-heading {
      padding: 0;
      & > button {
        background: none;
        border: none;
        width: 100%;
        text-align: left;
        padding: 0;
      }
    }
    .card-body {
      border-bottom: 1px solid #ebebeb;
    }
    h3.panel-title {
      background-color: #f9f9f9;
      border-bottom: 1px solid #ebebeb;
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase;
      color: #242424;
      display: block;
      padding: 16px 55px;
      position: relative;
      @media #{$xs-layout} {
        line-height: 22px;
        font-size: 14px;
        padding: 16px 30px 16px 40px;
      }

      &:hover {
        color: $theme-color;
      }
      &::before {
        color: $black;
        content: "\f107";
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 19px;
      }
      &:hover::before {
        color: $theme-color;
      }

      span {
        color: #242424;
        font-size: 15px;
        left: 20px;
        position: absolute;
        top: 16px;
      }
    }
    .myaccount-info-wrapper {
      padding: 30px 20px;
      background-color: #fff;
      .account-info-wrapper {
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 28px;
        padding-bottom: 30px;
        h4 {
          font-size: 15px;
          margin: 0;
          text-transform: uppercase;
        }
        h5 {
          font-size: 16px;
          letter-spacing: 0.2px;
          margin-top: 7px;
        }
      }
      .billing-info {
        margin-bottom: 20px;
        label {
          color: $black;
          font-size: 14px;
          text-transform: capitalize;
        }
        input {
          background: transparent none repeat scroll 0 0;
          border: 1px solid #ebebeb;
          color: $black;
          height: 40px;
          padding: 0 15px;
        }
      }
      .billing-back-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 26px;
        align-items: center;
        .billing-back {
          a {
            color: $black;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            &:hover {
              color: $theme-color;
            }
            i {
              font-size: 16px;
              color: $theme-color;
            }
          }
        }
        .billing-btn {
          button {
            background-color: #f2f2f2;
            border: medium none;
            color: $black;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 16px 35px 17px;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.3s ease 0s;
            &:hover {
              background: $theme-color;
              color: #fff;
            }
          }
        }
      }
      .entries-wrapper {
        border: 1px solid #eaeaea;
        position: relative;
        @media #{$xs-layout} {
          padding: 30px 10px;
          &::before {
            display: none;
          }
        }
        &::before {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          left: 50%;
          top: 0;
          background-color: #eaeaea;
        }
        .entries-info {
          padding: 30px 20px;
          @media #{$xs-layout} {
            padding: 0 10px 30px;
          }
          p {
            color: $black;
            font-size: 15px;
            margin: 0;
            text-transform: capitalize;
          }
        }
        .entries-edit-delete {
          a,
          button {
            background: none;
            border: none;
            background-color: $black;
            color: #fff;
            display: inline-block;
            line-height: 1;
            margin: 0 2px;
            padding: 12px 15px;
            text-transform: uppercase;
            font-weight: 500;
            &:hover {
              background-color: $theme-color;
            }
            &.edit {
              background-color: #df5c39;
              &:hover {
                background-color: $theme-color;
              }
            }
          }
        }
      }
    }
  }

  .course-menu {
    .nav-link.active {
      background-color: #fff !important;
      color: #28a466 !important;
      border-bottom: 2px solid #28a466 !important;
      margin-bottom: 5px;
      border-radius: 0px !important;
      font-weight: 500;
      @media #{$xs-layout} {
        font-size: 8px;
      }
    }
    .nav-link{
      @media #{$xs-layout} {
        font-size: 8px;
      }
    }
  }

  .sidebar.nav-item {
    // margin-left: -30px;
    // margin-right: -30px;
    // height: 38px;
  }
  .timwzone-dropdown {
    height: 60px;
    width: 300px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    font-weight: 200;
    option {
      font-weight: 200;
    }
  }

  .thumbail-img {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .main-header {
    background-color: #fff;
  }

  .profile-pic {
    padding: 15px;
    width: 40%;
    display: block;
    margin: auto;
  }
  .pad-15 {
    padding: 15px;
  }
  .profile{
    .fa-chevron-down{
      display: block;
    }
    .fa-chevron-up{
      display: none;
    }
    display: flex;
    align-items: center;
    position: relative;
  }
  .tab-name{
    margin-left: 15px;
  }
  .profile-icon {
    background-image: url("../../assets/imgs/person.svg");
  }
  .logout-icon{
    background-image: url("../../assets/imgs/logout-icon.svg");
  }
  .purchase-history-icon {
    background-image: url("../../assets/imgs/purchasehistory.svg");
  }
  .mycourses-icon {
    background-image: url("../../assets/imgs/mycourses.svg");
  }
  .subscription-icon {
    background-image: url("../../assets/imgs/subscription.svg");
  }
}
