.login-signup-container {
        min-height: calc(100vh - 40px);
        background-color: #f9f9f9;
        margin-bottom: -80px;

        @media (max-height: 414px) {
                min-height: calc(100vh);
                position: relative;
                top: 66px;
        }
        .inner-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: auto;
                text-align: center;
                width: 400px;
                @media (max-width: 576px) {
                        width: 310px;
                }
                height: min-content;
                max-height: 800px;
                border: 1px solid #ebebeb;
                border-radius: 3px;
                padding: 15px;
                background-color: #fff;
                color: #13984b;

                .login-form,
                .signup-form,
                .forgot-password-form {
                        @media (max-height: 414px) {
                                min-width: 275px;
                        }
                }

                .main-heading {
                        font-weight: 400;
                        font-size: 20px;
                        font-weight: 500;
                }
                .login-icon-btn {
                        width: 100%;
                        margin-top: 15px;
                        border-radius: 3px;
                        border: 1px solid #d3d3d3;
                        text-transform: uppercase;
                        font-size: 14px;
                        padding: 5px 0;
                }
                hr {
                        margin-top: 17px;
                        margin-bottom: 3px;
                }
                .showpassword,
                .hidepassword {
                        position: absolute;
                        margin-top: 25px;
                        margin-left: 20px;
                        text-align: end;
                        right: 31px;
                        width: 30px;
                }
                .showpassword {
                        .fa-eye {
                                display: block;
                        }
                        .fa-eye-slash {
                                display: none;
                        }
                }
                .password-container {
                        display: inline-flex;
                        width: 100%;
                }
                .hidepassword {
                        .fa-eye {
                                display: none;
                        }
                        .fa-eye-slash {
                                display: block;
                        }
                }
                .or-text {
                        background-color: white;
                        width: 40px;
                        margin: -28px auto 0;
                }

                .input-common {
                        margin-top: 15px;
                        position: relative;
                }

                .forgot-password-link {
                        color: #0D1362;
                        text-align: right;
                        display: block;
                        text-decoration: underline;
                }
                .signup-link,
                .login-link {
                        margin-top: 20px;
                        // text-decoration: underline;
                        color: #13984b;
                        font-weight: 600;
                }
                .login-btn,
                .sign-up-btn {
                        margin: 20px 0;
                }
                .partner-dropdown {
                        height: 60px;
                        width: 360px;
                        height: 40px;
                        border-radius: 3px;
                        border: 1px solid #e6e6e6;
                        font-weight: 200;
                        margin-top: 20px;
                        option {
                                font-weight: 200;
                        }
                }
                .supplier-check-container {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        .supplier-check-input {
                                height: 15px;
                                width: 15px;
                                margin-right: 10px;
                        }
                        .supplier-check-text {
                                color: #686868;
                                cursor: pointer;
                        }
                }
                .error-msg {
                        color: red;
                        text-align: left;
                }
        }
}
