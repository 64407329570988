.profile_info{
    width:636px;
    background-color: #fff;
    @media (max-width: 576px) {
        width: 100vw;
        height: 100vh;
    }

    
    .form_container{
        .row{
            margin-bottom:5px;
        }
        .label{
            font-size:12px;
            color: #707070;
            @media (max-width: 576px) {
                margin-top: 15px;
            }
            @media (min-width: 650px) and (max-width: 700px){
                top: 0px;
            }
        }
        input{
            color:#000;
            font-size:14px;
        }
        .err-msg{
            color:#BE1033;
        }


        .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .br_holder{
                width:391px;
                border:1px solid #ddd;
                text-align: right;
                border-radius: 6px;
                height:36px;
                .br_btn {
                  color:#000;
                  background-color:#F3C465;
                  font-size:14px;
                  border:none;
                  font-weight:400;
                  height:100%;
                  padding: 5px;
                  cursor: pointer;
                }
            }
          }
          
          .upload-btn-wrapper input[type=file] {
            position: absolute;
            right: 0;
            opacity: 0;
          }
    }
    h4{
        padding-left:20px;
        font-weight:600;
    }
    .frm_holder{
        padding:20px;
    }
    textarea{
        background-color: #fff;
    }
    .visually-hidden{
        border:none;
    }
    .preview_holder{
        width:100px;
        height:70px;
        border:1px solid #ddd;
    }
    .form-img__img-preview{
        width:100%;
        height:100%;
    }
    .btn-holder{
        text-align: right;
        margin-top: 10px;
        .cancel-btn{
            margin-right:20px;
            background: #fff;
            border: 2px solid #13984b;
            color:#13984b;
        }
    }
    /* textfield animation input css*/
    .wrapper {
        width: 100%;
        @media (max-width: 991px ) {
            padding: 10px 0
        }
        @media (max-width: 575px ) {
            padding-bottom: 10px;
        }
        .textField {
            padding: 10px;
            border: 1px solid #cccc;
            border-radius: 5px;
            outline: none;
            height: '40px';
            font-size: '14px';
            color: '#000';
        }
        .label {
            color:#999999;
            color:var(--color-gray-3);
        }
        .textField:focus + .lbl , .textField:not(:placeholder-shown) + .lbl{
           
        }
    }
}