.add-course-from-url-main-area {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
    .ce-logo {
        background-image: url("../../assets/imgs/CE_logo.svg");
        width: 200px;
        height: 74px;
        background-repeat: no-repeat;
    }
}
