.partner-landing-main-area {
    .us-exports {
        display: flex;
        align-items: center;
        padding: 50px 0;
        min-height: 430px;
        background-image: url("../imgs/livingston-hero-background.jpg");
    }
    .ioe-container {
        // background-color: #003c5d;
        background-image: linear-gradient(#003E60 , #003F62, #012C3F);
        height: 398px;
        @media (max-width: 768px) {
            height: 420px;
        }
        .h-info-box {
            height: 340px;
            margin: 50px 0px;
            @media (max-width: 768px) {
                margin: 30px 10px;
            }
            .text-left-heading {
                color: #fff;
                font-size: 30px;
                padding-bottom: 20px;
                font-weight: 800;
                @media (max-width: 768px) {
                    font-size: 26px;
                }
            }
            .text-left {
                color: #fff;
                font-size: 23px;
                line-height: 35px;
                @media (max-width: 1100px) {
                    font-size: 18px;
                    line-height: 27px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                b {
                    font-weight: 600;
                }
            }

            .play_btn_h {
                display: flex;
                align-items: center;
                margin-top: 10px;
                h3 {
                    color: #5598b9;
                    padding-left: 10px;
                    font-weight: 600;
                    cursor: pointer;
                }
                .play_btn {
                    width: 50px;
                    height: 50px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                    cursor: pointer;
                    .fa-play {
                        font-size: 14px;
                        color: #5598b9;
                        padding-left: 5px;
                    }
                }
            }
        }
        .empl-box {
            @media (max-width: 768px) {
                display: none;
            }
            .hero-text{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 50px 0px 30px 0px;
                padding-right: -10px;
                p{
                    font-size: 24px;
                    color: #fff;
                    font-weight: 600;
                }
            }
            .hero-logo1 {
                background-image: url("https://assets.contentenablers.com/storefront/imgs/IOE_header_logo.png");
                height: 119.45px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            .hero-ce-logo {
                background-image: url("https://assets.contentenablers.com/storefront/imgs/CE_logo_white_green.png");
                height: 49.33px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                margin: 30px 0px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
    .hero-section {
        background-color: #F7F7F7;
        // background-image: linear-gradient(#003E60 , #003F62, #012C3F);
        height: 398px;
        @media (max-width: 768px) {
            height: 420px;
        }
        .h-info-box {
            height: 340px;
            margin: 50px 0px;
            @media (max-width: 768px) {
                margin: 30px 10px;
            }
            .text-left-heading {
                color: #273C77;
                font-size: 35px;
                padding-bottom: 20px;
                font-weight: 800;
                @media (max-width: 768px) {
                    font-size: 26px;
                }
            }
            .text-left {
                color: #273C77;
                font-size: 22px;
                line-height: 35px;
                @media (max-width: 1100px) {
                    font-size: 18px;
                    line-height: 27px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                b {
                    font-weight: 600;
                }
            }

            .play_btn_h {
                display: flex;
                align-items: center;
                margin-top: 10px;
                h3 {
                    color: #AA2230;
                    padding-left: 10px;
                    font-weight: 600;
                    cursor: pointer;
                }
                .play_btn {
                    width: 50px;
                    height: 50px;
                    background-color: #273D76;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                    cursor: pointer;
                    .fa-play {
                        font-size: 14px;
                        color: #FFFFFF;
                        padding-left: 5px;
                    }
                }
            }
        }
        .empl-box {
            @media (max-width: 768px) {
                display: none;
            }
            .hero-text{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 65px 0px 30px 0px;
                padding-right: -10px;
                p{
                    font-size: 24px;
                    color: #273D76;
                    font-weight: 600;
                }
            }
            .hero-logo1 {
                background-image: url("https://assets.contentenablers.com/storefront/imgs/EGADD_header_logo.png");
                height: 74.45px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            .hero-ce-logo {
                background-image: url("https://assets.contentenablers.com/storefront/imgs/CE_logo_white_black.png");
                height: 42.33px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                margin: 30px 0px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
    .hero-section {
        display: flex;
        align-items: center;
        // padding: 50px 0 0 0;
        height: 390px;
        // background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        @media (min-width: 1900px) {
            min-height: 500px;
        }
        @media (max-width: 1100px) {
            // background-size: contain;
            display: block;
            height: auto;
        }
        @media (max-width: 768px) {
            padding: 20px;
            background-size: auto;
            height: 450px;
            background-position: unset;
        }
        .text-holder {
            margin: 15px 0px;
            .text-left-heading {
                font-size: 30px;
                display: flex;
                padding-bottom: 20px;
                font-weight: 700;
                color: #fff;
                @media (max-width: 768px) {
                    line-height: 30px;
                }
            }
            .text-left {
                text-align: left;
                font-size: 22px;
                line-height: 30px;
                color: #fff;
                font-weight: 100;
                @media (min-width: 1900px) {
                    font-size: 28px;
                    line-height: 38px;
                }
                @media (min-width: 1700px) {
                    line-height: 37px;
                }
                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 25px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                b {
                    font-weight: 600;
                }
                
                span {
                    font-weight: bold;
                }
            }

            .play_btn_h {
                display: flex;
                // justify-content: space-between;
                align-items: center;
                // margin: 50px 1px 0px 1px;
                margin-top: 20px;
                @media (max-width: 768px) {
                    margin: 25px 1px;
                }
                h3 {
                    color: #fff;
                    padding-left: 10px;
                    font-weight: 600;
                    cursor: pointer;
                }
                h6 {
                    color: #00a84f;
                    font-weight: 600;
                    padding-left: 5px;
                }
                .play_btn {
                    width: 50px;
                    height: 50px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                    cursor: pointer;
                    .fa-play {
                        font-size: 14px;
                        color: #AA2230;
                        padding-left: 5px;
                    }
                }
            }
        }

        .main-heading-container {
            display: flex;
            align-items: left;
            @media (max-width: 768px) {
                font-size: 30px;
                text-align: left;
            }
            .main-heading {
                font-size: 44px;
                line-height: 52px;
                max-width: 440px;
                color: #fff;
                font-weight: 600;
                min-width: 503px;
                @media (max-width: 1200px) {
                    font-size: 32px;
                    margin-bottom: 12px;
                    line-height: 37px;
                }
                @media (max-width: 768px) {
                    font-size: 25px;
                    min-width: auto;
                }
                color: #fff;
                font-weight: 600;
            }
        }
        .video-section-container {
            padding: 0;
        }
    }
    .schar-hero-background {
        background-image: url("../imgs/schar-hero-background.png");
        background-size: contain;
    }
    .ieit-hero-background {
        background-image: url("../imgs/ieit-hero-background.png");
        background-size: cover;
    }
    .abcd-hero-background {
        background-image: url("../imgs/ieit-hero-background.png");
        background-size: cover;
    }

    .livingston-hero-background {
        background-image: url("../imgs/livingston-hero-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
        .main-heading {
            color: #0e1467 !important;
        }
        @media (max-width: 992px) {
            min-height: 200px;
        }
    }

    .zte-hero-background {
        background-image: url("../imgs/zte-hero-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
        .main-heading {
            color: #0e1467 !important;
        }
        @media (max-width: 992px) {
            min-height: 200px;
        }
    }

    .sec-info {
        // border-bottom: 1px solid #ebebeb;

        p {
            font-size: 24px;
            text-align: left;
            margin: 30px 0;
            color: #fff;
            width: 616px;
            line-height: 28px;
            @media (max-width: 768px) {
                font-size: 13px;
                line-height: 17px;
                width: 100%;
            }
        }
        .color-text {
            color: #0e1467;
        }
        .sec-text {
            width: auto;
        }
    }

    .sec_title {
        .title_p {
            font-size: 30px;
            font-weight: bold;
            padding: 30px;
        }
        background-color: #fff;
    }
    .se-crs-info {
        padding: 50px 0;
        // max-width: 1200px;
        // margin: 0 auto;

        .sec-crs-row {
            .collection-info {
                // margin-top: 30px;
                @media (max-width: 768px) {
                    text-align: center;
                }
                .collection-img {
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                    display: block;
                    margin-bottom: 15px;
                }
                .update-row {
                    display: flex;

                    @media (max-width: 768px) {
                        flex-direction: column-reverse;
                    }
                }

                h4 {
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 38px;
                }
            }
            .practitioner-explore-btn {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                // display: none;
                text-transform: uppercase;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                }
                .btn-ghost {
                    background-color: #f3c465;
                }
            }

            p {
                margin-top: 10px;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
            }
        }
        .learn-more-btn {
            margin-top: 20px;
            display: block;
            max-width: 175px;
            margin: 20px auto 15px;
        }

        .explore-course-btn-container {
            display: flex;
            // justify-content: center;
            margin-top: 20px;
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
            }
            .explore-course-btn {
                text-transform: uppercase;
                background-color: #f3c465;
            }
        }

        .sec_certi_series {
            background-color: #ddd;
        }
    }

    .livingston-course-img {
        display: block;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 1200px) {
            max-width: 600px;
        }
    }

    .livingston-info-section {
        background-color: #f8f8f9;
        padding: 50px 0;

        .feature-row {
            padding: 50px 0;

            .text-container {
                display: flex;
                padding-left: 50px;
                // padding-top: 25px;

                @media (max-width: 768px) {
                    padding-left: 15px;
                    padding-top: 40px;
                }
                .sub-heading {
                    font-size: 24px;
                    margin-top: 10px;
                    font-weight: 600;
                    line-height: 26px;
                }
                p {
                    margin-top: 10px;
                    font-size: 15px;
                    line-height: 21px;
                }
                .explore-course-btn-container {
                    margin-top: 20px;
                }
            }
        }

        p {
            margin-top: 10px;
            font-size: 15px;
            line-height: 21px;
        }

        @media (max-width: 768px) {
            .feature-row:nth-child(even) {
                flex-direction: column-reverse;
            }
        }
    }

    .featured-courses-section {
        padding: 50px 0;
        background-color: #fff;
        .col-md-12 {
            overflow: hidden;
        }
        .explore-course-btn-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .explore-course-btn {
                text-transform: uppercase;
                background-color: #f3c46557;
                border: 1px solid #f3c465;
                .back-btn {
                    padding-left: 17px;
                }
            }
        }

        .f_title {
            font-size: 30px;
            font-weight: bold;
        }
    }

    .client-section {
        padding-top: 20px;
        // margin-bottom: -65px;
    }

    .custom_section_crs {
        .col-rev {
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
        .box-item {
            // margin: 0 20px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
            h4 {
                font-weight: 400;
                font-size: 32px;
                line-height: 38px;
                // width: 544px;
                margin-bottom: 20px;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            .cont {
                margin-top: 30px;
                margin-right: 30px;
            }

            .practitioner-explore-btn {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                // display: none;
                text-transform: uppercase;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                }
                .btn-ghost {
                    background-color: #f3c465;
                }
            }
            .explore-course-btn-container {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                }
                .explore-course-btn {
                    text-transform: uppercase;
                    background-color: #f3c465;
                }
            }

            .img-box-right {
                width: 100%;
                min-height: 567px;
                background-image: url("../../assets/imgs/meeting_1.png");
                @media (max-width: 768px) {
                    background-size: contain;
                    background-repeat: no-repeat;
                    min-height: 250px;
                }
            }
            .img-box-left {
                width: 100%;
                min-height: 567px;
                background-image: url("../../assets/imgs/meeting_2.png");
                @media (max-width: 768px) {
                    background-size: contain;
                    background-repeat: no-repeat;
                    min-height: 250px;
                }
            }

            .m_l20 {
                margin-left: 20px;
            }
        }
    }

    .course-off-section {
        background-color: #f1f1f1;
        min-height: 470px;
        h3 {
            font-size: 30px;
            font-weight: 600;
            margin: 50px 0px;
            text-align: center;
            color: #012c3f;
        }
        .course-offerings {
            // display: flex;
            align-items: center;
            justify-content: center;
            min-height: 500px;
            @media (max-width: 768px) {
                height: 450px;
            }
            .box-holder {
                margin-left: -45px;
                @media (max-width: 768px) {
                    overflow: auto;
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    // padding-left: 20px;
                }
                .box-off {
                    margin: 0px 30px;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    height: 430px;
                    width: 330px;
                    // width: 85%;
                    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                    position: relative;
                    background-color: #fff;
                    cursor: pointer;
                    @media (max-width: 768px) {
                        // width: 75vw;
                        height: 450px;
                        margin: 0px 15px;
                    }
                    @media (max-width: 1024px) and (min-width: 1000px){
                        width: 300px;
                        height: 465px;
                        margin: 0px 15px;
                    }
                    @media (min-width: 1900px) {
                        height: 450px;
                        width: 416px;
                    }
                    .thumbail-img {
                        background-size: cover;
                        background-repeat: no-repeat;
                        height: 170px;
                        border-top-right-radius: 6px;
                        border-top-left-radius: 6px;
                    }

                    .course-text-container {
                        min-height: 100px;
                        padding: 13px 11px;
                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: capitalize;
                            color: #221f1f;
                            padding-bottom: 8px;
                        }
                        p {
                            font-size: 12px;
                            line-height: 18px;
                            color: #666666;
                            font-weight: 500;
                            @media (min-width: 1700px) {
                                font-size: 14px;
                            }
                        }
                    }
                    .see-off-btn {
                        width: 100%;
                        background-color: #012c3f;
                        color: #fff;
                        font-weight: 600;
                        text-transform: uppercase;
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                        height: 40px;
                        position: absolute;
                        bottom: 0;
                    }
                    &:hover {
                        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                        transform: scale(1.02);
                        transition: all 0.3s ease-in;
                    }
                }
                // .box-item-0{
                //     margin-left: 0;
                // }
            }
            .btn-holder {
                margin-top: 65px;
                .see-off-btn {
                    background-color: #012c3f;
                    color: #fff;
                    border-radius: 4px;
                    height: 60px;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    font-size: 25px;
                    padding: 20px 30px;
                    width: 450px;
                    @media (max-width: 768px) {
                        font-size: 22px;
                        width: auto;
                    }
                }
            }
        }
    }
}

.p_r0 {
    padding-right: 0;
    @media (max-width: 768px) {
        padding-right: 15px;
    }
}
.p_l0 {
    padding-left: 0;
    @media (max-width: 768px) {
        padding-left: 15px;
    }
}
