/* =========================== video-text-section =========================== */
.intro-section{
    padding: 30px 0px;
    background-color: #fff;
    .video-section-course{
      padding-right: 25px;
    }
    .intro-text{
      .btn-holder{
        margin-top: 65px;
        .see-off-btn{
          width: 100%;
          background-color: #012C3F;
          color: #fff;
          border-radius: 4px;
          height: 60px;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          font-size: 25px;
          padding: 20px 30px;
          @media (max-width: 768px) {
            font-size: 22px;
        }
        }
      }
      
    }
}