.partner-list-area {
    .partner-list {
        max-width: 992px !important;
        .partner-link {
            color: $theme-color !important;
        }
        .partner-heading-container {
            margin: 150px auto 65px;
        }

        .partner-container {
            margin-bottom: 75px;
            .partner-logo {
                max-width: 300px;
                margin: 0 auto;
                display: block;
            }
            .partner-heading {
                font-size: 20px;
                font-weight: 500;
            }
            .partner-list {
                .partner-logo {
                }
            }
        }
    }
}
