.footer-container {
  background-color: #4f5759;
  padding-top: 50px;
  position: relative;

  @media (max-height: 414px) {
    padding-top: 50px;
  }
  .footer-content{
    // padding: 0px 50px;
  }

  p {
    color: #fff;
  }

  .about-half {
    .ce-logo-dark-bg {
      display: block;
      background-image: url("../../assets/imgs/CE_logo_white_green.svg");
      background-repeat: no-repeat;
      width: 250px;
      height: 35px;
      @media (max-width: 1200px) {
        width: 170px;
      }
      @media (max-width: 768px) {
        width: 275px;
      }
      background-position: center;
      background-size: contain;
    }
    .readMoreText {
      color: #f3c465 !important;
    }
    .about-heading {
      font-weight: 600;
      margin-top: 30px;
    }
    .read-more-less-container {
      color: #fff;
      font-weight: 400;
    }
  }
.footer-hr{
  display: none;
  @media (max-width: 768px) {
    display: block;
  width: 100vw;
  background-color: #fff;
}
}
  .link-outer-container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-top: 15px;
      flex-direction: column;
    }
    .link-container {
      a {
        display: block;
        color: $theme-color;
        margin-bottom: 6px;
        font-size: 12px;
      }
      @media (max-width: 768px) {
        a {
          font-size: 10px;
        }
        a::after {
          content: " |";
          padding-right: 5px;
        }
        a:last-child::after {
          content: "";
        }
      }
      @media (max-width: 320px) {
        a {
          font-size: 8px;
        }
      }
      .link-heading {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 16px;
        @media (max-width: 768px) {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
      @media (max-width: 768px) {
        .link-inner-container {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .payment-icons {
        width: 65px;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          flex-wrap: nowrap;
          width: 100%;
          align-items: center;
        }
        div {
          width: 31px;
          height: 31px;
          background-size: cover;
          @media (max-width: 768px) {
            margin-right: 15px;
          }
        }
        .mastercard-icon {
          background-image: url("../../assets/imgs/mastercard-icon.png");
        }
        .visa-icon {
          background-image: url("../../assets/imgs/visa-icon.png");
        }
        .amex-icon {
          background-image: url("../../assets/imgs/amex-icon.png");
        }
        .discover-icon {
          background-image: url("../../assets/imgs/discover-icon.png");
        }
        .apple-pay-icon {
          background-image: url("../../assets/imgs/apple-pay-icon.png");
          height: 25px;
        }
      }
    }
  }

  .copyright-heading {
    text-align: center;
    height: 50px;
    background-color: #576062;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 0 71px;
  }
}
