.policy-list-area{
    margin-top: 130px;
    div{
        margin: 0 auto;
    }
    a{
        color: #F3C565 !important;
    }
    h3{
        font-size: 20px;
        font-weight: 500;
    }
    ul{
        list-style: disc;
        color: #494949;
    }
}